import React from "react";
import { Link } from "react-router-dom";
// import { useRouter } from "next/router";
// import { useTranslation } from "next-i18next";
import NavbarRight from "./NavbarRight";
import ClearIcon from "@mui/icons-material/Clear";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfig from "../../../hooks/useConfig";
import NavPages from "./NavPages";

export default function Navigation() {
    const { i18n } = useConfig();

    // console.log('i18n', i18n)
    // const router = useRouter();
    // const { t } = useTranslation("");

    // useEffect(() => {
    //   let dir = router.locale == "ar" ? "rtl" : "ltr";
    //   let lang = router.locale == "ar" ? "ar" : "en";
    //   // @ts-ignore
    //   document.querySelector("html").setAttribute("dir", dir);
    //   // @ts-ignore
    //   document.querySelector("html").setAttribute("lang", lang);
    // }, [router.locale]);

    return (
        <nav
            className="navbar navbar-expand-lg bg-body-tertiary bg-dark"
            style={{ height: 60 }}
            data-bs-theme="dark"
        >
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    {/* <button
            className="navbar-toggler"
            type="button"
    
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
                    <img
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasExample"
                        aria-controls="offcanvasExample"
                        className="d-lg-none"
                        src={"/images/Hamburger.png"}
                        alt={""}
                    />
                    <img

                        className="d-none d-lg-inline"
                        src={"/images/Hamburger.png"}
                        alt={""}
                    />
                    <img className="logo" src={"/images/Branding-w2w.png"} alt={""} />
                </Link>
                <NavPages />
                <div className="d-flex mobile-view ms-auto">
                    <NavbarRight lang={i18n} />
                </div>

                <div
                    className="offcanvas offcanvas-end d-lg-none"
                    tabIndex={-1}
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                    data-bs-theme="light"
                >
                    <div className="offcanvas-header bg-white ">
                        <img
                            className="logo m-auto"
                            src={"/images/Branding-w2w-dark.png"}
                            alt={""}
                        />
                        <button
                            type="button"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            style={{
                                backgroundColor: "transparent",
                                color: "black",
                                border: "none",
                            }}
                        >
                            <ClearIcon />
                        </button>
                    </div>
                    <div className="offcanvas-body bg-white navbar-drawer">
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography>
                                    Dashboard
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Link className="d-block text-dark px-1 py-2" to="/landing" >Landing</Link>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Listing</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Link className="d-block text-dark px-1 py-2" to="/product-list" >Product List</Link>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Advertisment</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Link className="d-block text-dark px-1 py-2" to="/" >Ads...</Link>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                            >
                                <Typography>Orders</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Link className="d-block text-dark px-1 py-2" to="/purchase-list" >Purchase List</Link>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <div className="buttom-accordion">
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <div className="w-100">
                                        <Typography style={{ float: "left" }}>Currency</Typography>
                                        <Typography
                                            className="text-dark"
                                            style={{ float: "right" }}
                                        >
                                            USD
                                        </Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Currency...
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <div className="w-100">
                                        <Typography style={{ float: "left" }}>Language</Typography>
                                        <Typography
                                            className="text-dark"
                                            style={{ float: "right" }}
                                        >
                                            English
                                        </Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Suspendisse malesuada lacus ex, sit amet blandit leo
                                        lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-flex">
                    {/* <NavbarRight lang={router.locale?.toUpperCase()} /> */}
                </div>
            </div>
        </nav>
    );
}
